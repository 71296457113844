





























import { Component, Vue } from 'vue-property-decorator';
import { ref } from '@vue/composition-api';
import { versions, latestVersion, VersionInterface } from '../lib/versions';
import ViewWrapper from '../components/ViewWrapper.vue';

@Component({
  components: {
    ViewWrapper,
  },
  setup() {
    const versionList = ref(Object.keys(versions).reverse());
    const versionObj = ref<Record<string, VersionInterface>>(versions);
    const changesOrder: string[] = ['bugs', 'features', 'classes'];

    const getChangesOrderForVersion = (version: string): string[] => changesOrder
      .filter((key: string) => (versionObj.value as any)[version][key]
        && (versionObj.value as any)[version][key].length !== 0);

    // update latest battletris version
    window.localStorage.setItem('battletris-version', latestVersion);

    return {
      versionList,
      versionObj,
      getChangesOrderForVersion,
    };
  },
})
export default class Settings extends Vue {}
